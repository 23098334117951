import * as React from "react"

import Layout from "../components/layout"
import {Link} from 'gatsby';
import {Helmet} from 'react-helmet';

const NotFoundPage = () => (
  <Layout heroTitle="A manóba!" headerStyle={'waves'}>

    <Helmet>
      <title> Ügyesvagyok - Játékos mozgás kicsiknek és nagyoknak </title>
    </Helmet>

    <h2>
      Az oldal nem található...
    </h2>
    <p>
      ...vagy csak simán eltévedtünk. Szerintem <Link to="/">kezdjük ujra</Link>.
    </p>
  </Layout>
)

export default NotFoundPage
